* {
  margin: 0;
  padding: 0;
}
body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

.testimonial_section .testimonial_card {
  /* width: 300px; */
  /* padding: 20px; */
  min-height: 400px;
  background-color: #fff;
  border-radius: 12px;
  opacity: 0.8;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.testimonial_section .owl-item.active.center .testimonial_card {
  /* opacity: 1; */
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.testimonial_section .owl-item.active.center {
  display: flex;
  justify-content: center;
}

.testimonial_section .testimonial_card .testimonial_img {
  display: flex;
  justify-content: center;
}

.testimonial_section .testimonial_card .testimonial_img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.testimonial_section .testimonial_card .testimonial_txt {
  margin-top: 20px;
  text-align: center;
  color: #000;
}

.testimonial_section .testimonial_card .testimonial_txt .testimonial_name {
  font-family: "Ma Shan Zheng", sans-serif;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
}

.testimonial_section .testimonial_card .testimonial_txt .testimonial_name span {
  font-weight: 400;
  font-size: 17px;
}

@media (max-width: 768px) {
  .testimonial_section .testimonial_card {
    width: 100vw; /* Ensure the card takes the full width */
    max-width: 95%; /* Add some margin around the card */
    margin: 0 auto; /* Center the card */
    transform: scale(1); /* No scaling for small screens */
    opacity: 1; /* Ensure full visibility */
  }
}
@media (max-width: 400px) {
  .testimonial_card .testimonial_section {
    padding: 5px;
  }
}

@keyframes pulse-pop {
  0%,
  100% {
    transform: scale(1); /* Normal size */
  }
  50% {
    transform: scale(1.2); /* Slightly larger */
  }
}

.pulse-pop {
  animation: pulse-pop 1.3s infinite ease-in-out;
}
