@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Forum&family=Metamorphous&display=swap');

html {
    scroll-behavior: smooth;
  }
  